<template>
	<div class="pui-form">
		<v-form class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
			<v-row class="pui-form-layout">
				<v-col cols="12">
					<v-row dense class="mt-2">
						<v-col cols="4">
							<pui-text-field
								:id="`variable-portauthorityvariable`"
								v-model="model.variable"
								:label="$t('form.portauthorityvariable.variable')"
								disabled
								required
								toplabel
								maxlength="50"
							></pui-text-field>
						</v-col>
						<v-col cols="7">
							<pui-text-field
								:id="`value-portauthorityvariable`"
								v-model="model.value"
								:label="$t('form.portauthorityvariable.value')"
								:disabled="formDisabled"
								required
								toplabel
								maxlength="500"
							></pui-text-field>
						</v-col>
					</v-row>
				</v-col>
			</v-row>

			<!-- Page Footer -->
			<pui-form-footer v-if="!isModalDialog">
				<pui-form-footer-btns :formDisabled="formDisabled" :saveDisabled="saving" :save="save" :back="back"></pui-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';

export default {
	name: 'portauthorityvariable-form',
	mixins: [PuiFormMethodsMixin],
	components: {},
	data() {
		return {
			modelName: 'portauthorityvariable',
			parentModelName: 'portauthority'
		};
	},
	methods: {
		afterGetData() {
			this.setParentPk();
		},
		setParentPk() {
			const parentModel = this.$puiUtils.getPathModelMethodPk(this.$router, this.parentModelName);
			if (parentModel) {
				this.model.portauthorityid = parentModel.pk.id;
			}
		}
	},
	computed: {},
	created() {}
};
</script>
